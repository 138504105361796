// @file All network requests relating to library used on surface

import { apiUrlInterpolate } from '@@/bits/api_url_interpolate'
import { fetchJson } from '@@/surface/api_fetch'
import type { Library as LibraryType, UserId } from '@@/types'
import type { JsonAPIResponse } from '@padlet/arvo'
import { HTTPMethod } from '@padlet/fetch'

class LibraryApi {
  public static get libraryUrl(): string {
    return `/api/1/libraries?userId=\${ userId }&filter=\${ filter }`
  }

  public static buildUrl(url, options): string {
    return apiUrlInterpolate(url, options)
  }

  public static async fetchCreatableLibraries({ userId }, fetchOptions = {}): Promise<JsonAPIResponse<Library>> {
    return await fetchJson(this.buildUrl(this.libraryUrl, { userId, filter: 'wall_creatable' }), {
      method: HTTPMethod.get,
      ...fetchOptions,
    })
  }

  public static async fetchCreatableAndVisibleLibraries(
    { userId }: { userId: UserId },
    fetchOptions = {},
  ): Promise<JsonAPIResponse<LibraryType>> {
    return await fetchJson(this.buildUrl(this.libraryUrl, { userId, filter: 'wall_creatable_and_visible' }), {
      method: HTTPMethod.get,
      ...fetchOptions,
    })
  }

  public static async fetchViewableLibraries(
    { userId }: { userId: UserId },
    fetchOptions = {},
  ): Promise<JsonAPIResponse<LibraryType>> {
    return await fetchJson(this.buildUrl(this.libraryUrl, { userId, filter: 'all_walls_viewable' }), {
      method: HTTPMethod.get,
      ...fetchOptions,
    })
  }
}

export { LibraryApi }
